import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'
import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer'
import {SettingsEvents} from './constants'
import {fetchInitialState} from './controller-utils'
import {registerWidgetPluginHostListeners} from './controller-listeners'

export const createMembersPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  let widgetPluginService = null

  widgetPluginService = createMembersAreaWidgetPluginService()

  const initialise = async () => {
    await fetchInitialState({controllerParams, componentEventHandler})

    registerWidgetPluginHostListeners({
      widgetPluginService,
      controllerParams,
      componentEventHandler,
    })
  }

  return {
    pageReady: async () => {
      const isRendered = widgetPluginService?.getIsRendered() ?? true

      if (!isRendered) {
        controllerParams.controllerConfig.setProps({isRendered})
        return
      }

      return initialise()
    },
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    exports: () => createWidgetPluginExports(widgetPluginService, initialise),
  }
}

export default createMembersPageController
